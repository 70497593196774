import React, { useState } from "react";
import styles from "./api.module.scss";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/vsDark";
import Arrow from "../../../images/LP/Home/api/right-arrow.svg";

const exampleCode = [
  `curl --location --request POST 'https://api.us.springverify.com/employee/invite' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer JWT_TOKEN' \
--data-raw '{
    "email_list": [
        "venunath2@gmail.com"
    ],
    "employee_details": [
        {
            "email": "venunath2@gmail.com",
            "first_name": "abcd",
            "last_name": "xyc",
            "middle_name": "zxcz",
            "phone": "21321313",
            "birthdate": "12-12-2012"
        }
    ],
    "package": "bronze",
    "addOns": {
        "employment": 0,
        "education": 0,
        "license": 0,
        "driving_license": 0,
        "civil_court": 0,
        "all_county_criminal_search": false,
        "county_criminal_search": 0,
        "MVR": false
    }
}'`,

  `// FETCH

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "Bearer JWT_TOKEN");

var raw = JSON.stringify({"email_list":["venunath2@gmail.com"],"employee_details":[{"email":"venunath2@gmail.com",
"first_name":"abcd","last_name":"xyc","middle_name":"zxcz","phone":"21321313","birthdate":"12-12-2012"}],"package":"bronze",
"addOns":{"employment":0,"education":0,"license":0,"driving_license":0,"civil_court":0,"all_county_criminal_search":false,
"county_criminal_search":0,"MVR":false}});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://api.us.springverify.com/employee/invite", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));

// REQUEST

var request = require('request');
var options = {
  'method': 'POST',
  'url': 'https://api.us.springverify.com/employee/invite',
  'headers': {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer JWT_TOKEN' 
  },
  body: JSON.stringify({"email_list":["venunath2@gmail.com"],"employee_details":[{"email":"venunath2@gmail.com","first_name":"abcd",
  "last_name":"xyc","middle_name":"zxcz","phone":"21321313","birthdate":"12-12-2012"}],"package":"bronze","addOns":{"employment":0,
  "education":0,"license":0,"driving_license":0,"civil_court":0,"all_county_criminal_search":false,"county_criminal_search":0,"MVR":false}})
};
request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
});`,

  `<?php
require_once 'HTTP/Request2.php';
$request = new HTTP_Request2();
$request->setUrl('https://api.us.springverify.com/employee/invite');
$request->setMethod(HTTP_Request2::METHOD_POST);
$request->setConfig(array(
  'follow_redirects' => TRUE
));
$request->setHeader(array(
  'Content-Type' => 'application/json',
  'Authorization' => 'Bearer JWT_TOKEN'
));
$request->setBody('{\n    "email_list": [\n        "venunath2@gmail.com"\n    ],\n    
"employee_details": [\n        {\n            "email": "venunath2@gmail.com",\n            
"first_name": "abcd",\n            "last_name": "xyc",\n            "middle_name": "zxcz",\n            
"phone": "21321313",\n            "birthdate": "12-12-2012"\n        }\n    ],\n    "package": "bronze",\n    
"addOns": {\n        "employment": 0,\n        "education": 0,\n        "license": 0,\n        
"driving_license": 0,\n        "civil_court": 0,\n        "all_county_criminal_search": false,\n        
"county_criminal_search": 0,\n        "MVR": false\n    }\n}');
try {
  $response = $request->send();
  if ($response->getStatus() == 200) {
    echo $response->getBody();
  }
  else {
    echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .
    $response->getReasonPhrase();
  }
}
catch(HTTP_Request2_Exception $e) {
  echo 'Error: ' . $e->getMessage();
}`,
  `import requests

url = "https://api.us.springverify.com/employee/invite"

payload="{\n    \"email_list\": [\n        \"venunath2@gmail.com\"\n    ],\n    
\"employee_details\": [\n        {\n            \"email\": \"venunath2@gmail.com\",\n            
\"first_name\": \"abcd\",\n            \"last_name\": \"xyc\",\n            \"middle_name\": \"zxcz\",\n            
\"phone\": \"21321313\",\n            \"birthdate\": \"12-12-2012\"\n        }\n    ],\n    
\"package\": \"bronze\",\n    \"addOns\": {\n        \"employment\": 0,\n        \"education\": 0,\n        
\"license\": 0,\n        \"driving_license\": 0,\n        \"civil_court\": 0,\n        
\"all_county_criminal_search\": false,\n        \"county_criminal_search\": 0,\n        \"MVR\": false\n    }\n}"
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer JWT_TOKEN'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)`,

  `require "uri"
require "net/http"

url = URI("https://api.us.springverify.com/employee/invite")

https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["Content-Type"] = "application/json"
request["Authorization"] = "Bearer JWT_TOKEN"
request.body = "{\n    \"email_list\": [\n        
  \"venunath2@gmail.com\"\n    ],\n    
  \"employee_details\": [\n        {\n            \"email\": \"venunath2@gmail.com\",\n            
  \"first_name\": \"abcd\",\n            \"last_name\": \"xyc\",\n            
  \"middle_name\": \"zxcz\",\n            \"phone\": \"21321313\",\n           
  \"birthdate\": \"12-12-2012\"\n        }\n    ],\n    \"package\": \"bronze\",\n    
  \"addOns\": {\n        \"employment\": 0,\n        \"education\": 0,\n        
  \"license\": 0,\n        \"driving_license\": 0,\n        \"civil_court\": 0,\n        
  \"all_county_criminal_search\": false,\n        \"county_criminal_search\": 0,\n        
  \"MVR\": false\n    }\n}"

response = https.request(request)
puts response.read_body`,
];

const VerificationExp = () => {
  const [selectedLang, setSelectedLang] = useState(0);

  const langs = ["cURL", "Javascript", "PHP", "Python", "Ruby"];

  return (
    <div className={styles.bg}>
      <section className={styles.verificationExp}>
        <div>
          <div className={styles.verificationExpRightMob}>
            <span>API Documentation</span>
          </div>
          <div className={styles.code}>
            <div className={styles.langs}>
              {langs.map((lang, i) => (
                <button
                  onClick={() => {
                    setSelectedLang(i);
                  }}
                  className={i === selectedLang && styles.active}
                >
                  {lang}
                </button>
              ))}
            </div>
            <Highlight
              {...defaultProps}
              code={exampleCode[selectedLang]}
              theme={theme}
              language="jsx"
            >
              {({ tokens, getLineProps, getTokenProps }) => (
                <pre>
                  {tokens.map((line, i) => (
                    <div {...getLineProps({ line, key: i })}>
                      {line.map((token, key) => (
                        <span {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  ))}
                </pre>
              )}
            </Highlight>
          </div>
        </div>
        <div className={styles.verificationExpRight}>
          <span>API Documentation</span>
          <h2>
            Powerful and flexible <u>API</u>
          </h2>
          <p>
            Our powerful API gives you an unmatched verification experience.
            Meaning, you can start building on your existing HRIS and ATS
            platforms effortlessly. Plus, Blockchain and Machine Learning make
            your background checks faster, easier, and more accurate.{" "}
          </p>
          <a
            href="https://docs-bgv.springverify.com/#add-education"
            target="_blank"
          >
            Access our API Documentation
            <img src={Arrow} />
          </a>
        </div>
      </section>
    </div>
  );
};

export default VerificationExp;
